import { useFormContext, useWatch } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";

import { unitLong } from '../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../../sustell_15/components/CompoundFeedWizard/types";
import ReactHookDsmInput from "../../Helpers/ReactHookDsmInput2";
import ReactHookDsmSelect from "../../Helpers/ReactHookDsmSelect2";
import { isShrimp } from "../../../sustell_15/helpers/animals";
import { StageType } from "../../../../graphql/types";
// import { ClosedCaptionOutlined } from "@material-ui/icons";
const SustellNewSingleIngredientFeed = (props) => {
  const { 
    fieldItemPrefix, 
    subFieldName,
    label, 
    index, 
    updateTotalFeedIntake, 
    formState, 
    animalType, 
    stageType,
    availableFeedItems, 
    availableOrigins,
    removeHandler,
    isBeefFeed = false,
  } = props;

  const { register, control, errors, getValues } = useFormContext();
  const intl = useIntl();
  const resolvePath = (object, path, defaultValue) => path
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object);

  const idName = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].id`;
  const ftName = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].feedType`;
  const originName = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].origin`;
  const watch = useWatch({control, name: ftName});    
  // will not rename field itself just labels and placeholders for salmon, correct usage is handled by the mapper
  const name   = `${fieldItemPrefix  }.${  subFieldName  }[${  index  }].kgPerAnimal`; 
  // useEffect(() => () => {
  //     updateTotalFeedIntake(name, 0);
  //   }, [name, updateTotalFeedIntake])
 
  const localError = resolvePath(errors, name, null);
  const feedItemsLoaded = availableFeedItems?.some(x => x.type !== '');
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  let selectedItemValue = getValues(ftName);
  let selectedItemOrigin = getValues(originName);
  
  selectedItemValue = selectedItemValue !== undefined ? selectedItemValue : "";
  selectedItemOrigin = selectedItemOrigin !== undefined ? selectedItemOrigin : "";
  
  let amountValue =getValues(name);
  amountValue = amountValue !== undefined ? amountValue : "";
  const labelKey =  "SUSTELL.AMOUNT";

  const getAmountTooltip = () => {
    if (isShrimp(animalType)) {
      return (stageType === StageType.Hatching 
        ? 'SINGLE_INGREDIENT_AMOUNT.SHRIMP_HATCHERY.TOOLTIP' 
        : 'SINGLE_INGREDIENT_AMOUNT.SHRIMP.TOOLTIP');
    }
    if (isBeefFeed) return 'SINGLE_INGREDIENT_AMOUNT.BEEF.TOOLTIP';
    return 'SINGLE_INGREDIENT_AMOUNT.TOOLTIP';
  }
  
  return (
    <DsmGrid style={{grid:"auto/6fr 4fr 140px 32px", gap: "var(--dsm-spacing-px-4)", alignItems: 'start', alignContent:' space-between', padding: "0px"}}>
       <input
        ref={register()}
        type="hidden"
        name={idName}
        defaultValue={getValues(idName) || ''}
      />
      <ReactHookDsmSelect
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart1`}
        label={label}
        name={ftName}
        defaultValue={selectedItemValue}
        disabled={formState === FormType.View}
        options = { availableFeedItems }
      />
      <ReactHookDsmSelect
        key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPartOrigin`}
        label={intl.formatMessage({id: "SUSTELL.STAGE.FEED.SINGLE_INGREDIENT.ORIGIN"})}
        name={originName}
        defaultValue={selectedItemOrigin}
        disabled={formState === FormType.View}
        options = { availableOrigins }
        required = {!!watch}
      />
      <ReactHookDsmInput
        style={{ marginTop: '3px'}}
        key={`${fieldItemPrefix  }.${  subFieldName  }[${  index  }]_inputPart2`}
        defaultValue={amountValue}
        name={name}
        label={ intl.formatMessage( {id: labelKey }) }
        adornment={(userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : "kg")}
        helperText={(localError && localError.message) ? localError.message : ""}
        error={Boolean(localError)}
        disabled={formState === "view" || !watch}
        tooltip={intl.formatMessage({
          id: getAmountTooltip(),
        })}
        type="number"
        required = {!!watch}
      />
      {formState !== "view" &&
        <DsmButton 
          variant="text" 
          iconOnly  
          destructive 
          onClick={removeHandler} 
          style={{padding: "0px", marginTop: '24px'}}
        >
          <DsmIcon slot="before"
            name="general/trash-01" />
        </DsmButton>
      }
    </DsmGrid>
  )
};
export default SustellNewSingleIngredientFeed;