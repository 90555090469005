import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import axios from 'axios';

import { useSelector } from 'react-redux';
import useStyles from '../../../modules/UserProfile/profileStyles/myProfileStyles';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import { CSSClassesList } from '../../helpers/helperTypes';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import { DsmButtonV2 } from '../helpers/DsmButton';
import { FileUpload, FileUploadProps } from './FileUpload';
import lookupValues from '../../helpers/lookupValues';
import {
  getIncidentSignedUrl,
  listCustomers,
  listUsers,
} from '../../../../graphql/queries';
import { Customer, User } from '../../../../graphql/types';
import { createIncident } from '../../../../graphql/mutations';
import useUserData from '../../../modules/Auth/getAuthUserData';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { masterDataV2 } from '../CompoundFeedWizard/utils';

interface DataOption {
  label: string;
  value: string;
}

const TechnicalSupport: React.FC = () => {
  const intl = useIntl();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        incidentLocation: Yup.string().required(),
        incidentType: Yup.string().required(),
        speciesType: Yup.string().required(),
        description: Yup.string().required(),
      })
    ),
    defaultValues: {
      incidentLocation: '',
      incidentType: '',
      speciesType: '',
      description: '',
    },
  });
  const history = useHistory();
  const classes = useStyles();
  const gridClasses = processAndStageStylesV2() as CSSClassesList;
  const [selectedValue, setSelectedValue] = React.useState('');
  const [isIncidentForError, setIsIncidentForError] = React.useState(false);
  const [filesData, setFilesData] = React.useState<
    {
      file: File;
      name: string;
    }[]
  >();
  const [unsupportedFiles, setUnsupportedFiles] = React.useState<string[]>();
  const user = useUserData();
  const selectedCustomer = useSelector(
    (state: { selectedCustomer: { value: { name: string } } }) =>
      state?.selectedCustomer
  );
  const [customers, setCustomers] = React.useState<Customer[]>();
  const [users, setUsers] = React.useState<User[]>();
  const [userNameOption, setUserNameOption] =
    React.useState<DataOption | null>();
  const [userEmailOption, setUserEmailOption] =
    React.useState<DataOption | null>();
  const [userCompanyNameOption, setUserCompanyNameOption] =
    React.useState<DataOption | null>();
  const [progressBarValues, setProgressBarValues] = React.useState(new Map());
  const [isSaving, setIsSaving] = React.useState(false);
  const [isIncidentSubmitted, setIsIncidentSubmitted] = React.useState(false);
  const { formSubmissionEvent } = useAdobeDataLayer();

  const updateProgressBarValues = (k: string, v: number) => {
    setProgressBarValues(new Map(progressBarValues.set(k, v)));
  };
  const cancelChanges = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const setLogiUser = () => {
    if (user) {
      const firstName = user['attributes'] && user['attributes']['given_name'];
      const lastName = user['attributes'] && user['attributes']['family_name'];
      const email = user['attributes'] && user['attributes']['email'];
      handleNameChange({
        label: `${firstName} ${lastName}`,
        value: `${lastName} ${lastName}`,
      });
      handleEmailChange({
        label: `${email}`,
        value: `${email}`,
      });
      if (selectedCustomer) {
        handleCompanyNameChange({
          label: `${selectedCustomer?.value?.name}`,
          value: `${selectedCustomer?.value?.name}`,
        });
      }
    }
  };

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value);
    setIsIncidentForError(false);
    if (event.target.value === 'no') {
      setLogiUser();
    } else if (event.target.value === 'yes') {
      handleNameChange(null);
      handleEmailChange(null);
      handleCompanyNameChange(null);
    }
  };

  const uploadFile = async (
    items: {
      file: File;
      name: string;
      key?: string;
      signedUrl?: string;
    }[]
  ) => {
    const attachmentS3Keys: string[] = [];
    for await (const item of items) {
      try {
        const result = (await API.graphql({
          query: getIncidentSignedUrl,
          variables: {
            key: item.name,
            method: 'PUT',
          },
        })) as Promise<{
          data: { getIncidentSignedUrl: { key: string; signedUrl: string } };
        }>;
        if ((await result).data?.getIncidentSignedUrl) {
          item.key = (await result).data.getIncidentSignedUrl.key;
          item.signedUrl = (await result).data.getIncidentSignedUrl.signedUrl;
        }
        if (item.signedUrl && item.key) {
          // now do a PUT request to the pre-signed URL
          await axios
            .put(item.signedUrl, item.file, {
              onUploadProgress: (progressEvent) => {
                if (progressEvent.progress) {
                  const progressValue = Math.round(
                    (progressEvent.loaded /
                      (progressEvent?.total || progressEvent.loaded)) *
                      100
                  );
                  updateProgressBarValues(item.name, progressValue);
                }
              },
            })
            .then((response) => {
              if (response.status === 200) {
                attachmentS3Keys.push(item.key as string);
              }
            });
        }
      } catch (err) {
        console.log('error: ', err);
        if (err.errors?.length > 0) {
          const error = err.errors[0];
          alert(
            `Attachement ${  item.name  } failed to upload.\n${  error.message}`
          );
        }
      }
    }
    submitIncident(attachmentS3Keys);
  };

  const onSubmit = async () => {
    if (filesData && filesData.length > 0) {
      setIsSaving(true);
      await uploadFile(filesData);
    } else {
      submitIncident();
    }
  };

  const getRandomNumbers = () => Math.random().toString().substring(2, 10);

  const nl2br = (str: string, replaceMode: boolean, isXhtml: boolean) => {
    const breakTag = isXhtml ? '<br />' : '<br>';
    const replaceStr = replaceMode ? `$1${  breakTag}` : `$1${  breakTag  }$2`;
    return (`${str  }`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  };

  const submitIncident = (attachmentS3Keys?: string[]) => {
    if (!selectedValue) {
      setIsIncidentForError(true);
      return;
    }
    if (!userNameOption) {
      return;
    }
    if (!userEmailOption) {
      return;
    }
    if (!userCompanyNameOption) {
      return;
    }
    (async () => {
      formSubmissionEvent(
        'Report an incident',
        'add',
        methods.getValues('speciesType')
      );
      try {
        const inputRequest = {
          ...methods.getValues(),
          description: nl2br(methods.getValues('description'), true, true),
          userName: userNameOption.value,
          userEmailAddress: userEmailOption.value,
          userCompanyName: userCompanyNameOption.value,
          attachementKeys: attachmentS3Keys,
          incidentID: `STL-${getRandomNumbers()}`,
        };
        const variables = {
          input: inputRequest,
        };
        const result = await API.graphql({
          query: createIncident,
          variables,
        });
        setIsSaving(false);
        if (result) {
          setIsIncidentSubmitted(true);
        }
      } catch (err) {
        console.log('error: ', err);
        setIsSaving(false);
      }
    })();
  };

  const fileUploadProp: FileUploadProps = {
    accept: 'image/*',
    multiple: true,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const files = [];
        for (const file of event.target.files) {
          if (filesData && filesData.length > 0) {
            const isExists = filesData.find((fd) => fd.name === file.name);
            if (!isExists) {
              files.push({ file, name: file.name });
            }
          } else {
            files.push({ file, name: file.name });
          }
        }
        setFilesData(
          filesData && filesData.length > 0 ? filesData?.concat(files) : files
        );
      }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const files = [];
      const notSupportedFiles = [];
      for (const file of event.dataTransfer.files) {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png'
        ) {
          if (filesData && filesData.length > 0) {
            const isExists = filesData.find((fd) => fd.name === file.name);
            if (!isExists) {
              files.push({ file, name: file.name });
            }
          } else {
            files.push({ file, name: file.name });
          }
        } else {
          notSupportedFiles.push(`${file.name} is not supported file`);
        }
      }
      setUnsupportedFiles(notSupportedFiles);
      setFilesData(
        filesData && filesData.length > 0 ? filesData?.concat(files) : files
      );
    },
  };

  const getUserNames = () => {
    const userNames: DataOption[] = [];
    if (users && users.length > 0) {
      users.forEach((usr) => {
        if (usr.firstName || usr.lastName)
          userNames.push({
            label: `${usr.firstName} ${usr.lastName}`,
            value: `${usr.firstName} ${usr.lastName}`,
          });
      });
    }
    return userNames;
  };

  const handleNameChange = (option: DataOption | null) => {
    setUserNameOption(option);
  };

  const getUserEmails = () => {
    const userEmails: DataOption[] = [];
    if (users && users.length > 0) {
      users.forEach((usr) => {
        if (usr.email)
          userEmails.push({
            label: `${usr.email}`,
            value: `${usr.email}`,
          });
      });
    }
    return userEmails;
  };

  const handleEmailChange = (option: DataOption | null) => {
    setUserEmailOption(option);
  };

  const getUserCompanyNames = () => {
    const companyNames: DataOption[] = [];
    if (customers && customers.length > 0) {
      customers.forEach((customer) => {
        if (customer.name)
          companyNames.push({
            label: `${customer.name}`,
            value: `${customer.name}`,
          });
      });
    }
    return companyNames;
  };

  const handleCompanyNameChange = (option: DataOption | null) => {
    setUserCompanyNameOption(option);
  };

  const createAnotherTicket = () => {
    setIsIncidentSubmitted(false);
    setFilesData([]);
  };

  const canSendForOtherUser = () => {
    const userGroups =
      user &&
      user['signInUserSession']['accessToken']['payload']['cognito:groups'];
    return (
      userGroups &&
      (Object.values(userGroups).includes('Administrators') ||
        Object.values(userGroups).includes('Support'))
    );
  };

  useEffect(() => {
    const loadListOfCustomers = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      (
        API.graphql({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          query: listCustomers,
        }) as Promise<{ data: { listCustomers: { items: Customer[] } } }>
      ).then(
        (response) => {
          setCustomers(response.data.listCustomers.items);
        },
        (reason: any) => {
          console.log('Error fetching selected database: ', reason);
        }
      );
    };
    const loadUsers = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      (
        API.graphql({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          query: listUsers,
        }) as Promise<{ data: { listUsers: { items: User[] } } }>
      ).then(
        (response) => {
          setUsers(response.data.listUsers.items);
        },
        (reason: any) => {
          console.log('Error fetching selected database: ', reason);
        }
      );
    };
    if (canSendForOtherUser()) {
      loadListOfCustomers();
      loadUsers();
    }
    if (!canSendForOtherUser()) {
      setSelectedValue('no');
    }
    setLogiUser();
  }, [user]);

  const removeFile = (fileName: string) => {
    const updatedFilesData = filesData?.filter(
      (file) => file.name !== fileName
    );
    setFilesData(updatedFilesData);
  };

  const getTargetSpecies = () => {
    const species: Set<string> = new Set()
    Object.keys(masterDataV2.target_species)
      .map(dbFoundation => masterDataV2.target_species[dbFoundation as keyof typeof masterDataV2.target_species]
      .forEach(specie => species.add(specie.display_name)));
    return [...species];
  }

  getTargetSpecies()

  return (
    <>
      {isIncidentSubmitted && (
        <div>
          <div className={classes.formSection}>
            <Typography className={classes.profileTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.TITLE',
              })}
            </Typography>
          </div>
          <div
            style={{
              textAlign: 'center',
              border: '1px solid #f0f0f0',
              height: '306px',
              padding: '100px',
            }}
          >
            <DsmIcon
              name="general/check-circle"
              style={{
                height: '28px',
                color: '#007E4B',
              }}
            />
            <div
              style={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.SUCCESS.MESSAGE.TITLE',
              })}
            </div>
            <div className={classes.profileDescription}>
              {intl.formatMessage(
                {
                  id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.SUCCESS.MESSAGE.DESCRIPTION',
                },
                { br: <br /> }
              )}
            </div>
            <DsmButtonV2
              variant="contained"
              type="submit"
              style={{ marginTop: '40px' }}
              onClick={createAnotherTicket}
            >
              <span className={classes.saveBtnTxt}>
                {intl.formatMessage({
                  id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.SUCCESS.REPORT.ANOTHER.INCIDENT.BUTTON',
                })}
              </span>
            </DsmButtonV2>
          </div>
        </div>
      )}
      {!isIncidentSubmitted && (
        <FormProvider {...methods}>
          <form
            className={classes.formContainer}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="card-body p-0">
              <div className={classes.formSection}>
                <Typography className={classes.profileTitle}>
                  {intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.TITLE',
                  })}
                </Typography>
                <Typography className={classes.profileDescription}>
                  {intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.DESCRIPTION',
                  })}
                </Typography>
              </div>
              {canSendForOtherUser() && (
                <DsmGrid className={gridClasses.dsmGridOneColumn}>
                  <FormControl>
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={selectedValue}
                      onChange={handleRadioButtonChange}
                      row
                    >
                      {' '}
                      <FormLabel
                        style={{
                          color: '#1b1b1b',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '45px',
                          paddingRight: '25px',
                        }}
                      >
                        {intl.formatMessage({
                          id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.QUESTION',
                        })}
                      </FormLabel>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={intl.formatMessage({
                          id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.QUESTION_YES_OPTION',
                        })}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={intl.formatMessage({
                          id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.QUESTION_NO_OPTION',
                        })}
                      />
                    </RadioGroup>
                    <Typography className={classes.profileDescription}>
                      {intl.formatMessage({
                        id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.QUESTION_HELP_TEXT',
                      })}
                    </Typography>
                    {isIncidentForError && (
                      <Typography
                        className={classes.profileDescription}
                        style={{ color: '#e51f22' }}
                      >
                        {intl.formatMessage({
                          id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.QUESTION_ERROR',
                        })}
                      </Typography>
                    )}
                  </FormControl>
                </DsmGrid>
              )}
              <DsmGrid
                className={gridClasses.dsmGridthreeColumnWithPadding}
                style={{
                  padding: '30px 0px 15px 0px',
                }}
              >
                <div>
                  <InputLabel style={{ fontWeight: 500, fontSize: '13px' }}>
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.NAME',
                    })}
                  </InputLabel>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.NAME',
                    })}
                    isSearchable
                    isClearable
                    value={userNameOption}
                    onChange={handleNameChange}
                    options={getUserNames()}
                    required
                    isDisabled={
                      selectedValue === 'no' || !canSendForOtherUser()
                    }
                  />
                </div>

                <div>
                  <InputLabel style={{ fontWeight: 500, fontSize: '13px' }}>
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.EMAIL',
                    })}
                  </InputLabel>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.EMAIL',
                    })}
                    isSearchable
                    isClearable
                    value={userEmailOption}
                    onChange={handleEmailChange}
                    options={getUserEmails()}
                    required
                    isDisabled={
                      selectedValue === 'no' || !canSendForOtherUser()
                    }
                  />
                </div>

                <div>
                  <InputLabel style={{ fontWeight: 500, fontSize: '13px' }}>
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.COMPANYNAME',
                    })}
                  </InputLabel>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.COMPANYNAME',
                    })}
                    isSearchable
                    isClearable
                    value={userCompanyNameOption}
                    onChange={handleCompanyNameChange}
                    options={getUserCompanyNames()}
                    required
                    isDisabled={
                      selectedValue === 'no' || !canSendForOtherUser()
                    }
                  />
                </div>
              </DsmGrid>
              <DsmGrid
                className={gridClasses.dsmGridthreeColumnWithPadding}
                style={{
                  padding: '0px',
                }}
              >
                <ReactHookDsmSelect
                  name="incidentLocation"
                  label={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.LOCATION',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.LOCATION.HINT',
                  })}
                  displayEmpty
                  disabled={false}
                  options={Object.entries(lookupValues.incidentLocation).map(
                    ([i, il]) => ({
                      value: `${i}`,
                      text: `${il.label}`,
                    })
                  )}
                  required
                />
                <ReactHookDsmSelect
                  name="incidentType"
                  label={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.TYPE',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.TYPE.HINT',
                  })}
                  displayEmpty
                  disabled={false}
                  options={Object.entries(lookupValues.incidentTypes).map(
                    ([i, it]) => ({
                      value: `${i}`,
                      text: `${it.label}`,
                    })
                  )}
                  required
                />
                <ReactHookDsmSelect
                  name="speciesType"
                  label={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.SPECIES.TYPE',
                  })}
                  disabled={false}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.SPECIES.TYPE.HINT',
                  })}
                  displayEmpty
                  options={getTargetSpecies().map(
                    (value) => ({
                      value,
                      text: value,
                    })
                  )}
                  required
                />
              </DsmGrid>
              <DsmGrid
                className={gridClasses.dsmGridOneColumn}
                style={{
                  paddingTop: '15px',
                  width: '66%',
                }}
              >
                <ReactHookDsmInput
                  name="description"
                  label={`${intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.DESCRIPTION',
                  })}`}
                  multiline
                  required
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.DESCRIPTION.HINT',
                  })}
                />
              </DsmGrid>
              <DsmGrid
                className={gridClasses.dsmGridOneColumn}
                style={{ paddingTop: '15px' }}
              >
                <div>
                  <span style={{ fontWeight: 500 }}>
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.ATTACHMENT',
                    })}
                  </span>
                  <span className={classes.profileDescription}>
                    {' '}
                    {intl.formatMessage({
                      id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.ATTACHMENT.HINT',
                    })}
                  </span>
                  <FileUpload {...fileUploadProp} />
                  {isSaving &&
                    filesData?.map((file) => (
                        <LinearProgress
                          key={file.name}
                          variant="determinate"
                          value={
                            progressBarValues.get(file?.name)
                              ? progressBarValues.get(file?.name)
                              : 0
                          }
                          style={{ margin: '10px 5px' }}
                        />
                      ))}
                  {unsupportedFiles && unsupportedFiles.length > 0 && (
                    <h6
                      style={{
                        fontWeight: 500,
                        color: 'var(--error-dsm-red, #E51F22)',
                      }}
                    >
                      {unsupportedFiles?.map((file) => (
                          <h6
                            style={{
                              fontWeight: 500,
                              color: 'var(--error-dsm-red, #E51F22)',
                            }}
                            key={file}
                          >
                            {file}
                          </h6>
                        ))}
                    </h6>
                  )}
                  {filesData && filesData.length > 0 && (
                    <div>
                      {filesData?.map((file) => (
                          <div style={{ display: 'flex' }} key={file.name}>
                            <h6 style={{ fontWeight: 500, width: '30%' }}>
                              {file.name}
                            </h6>
                            <DsmIcon
                              slot="before"
                              name="general/trash-01"
                              style={{
                                paddingRight: '5px',
                                height: '20px',
                                width: '20px',
                                color: 'var(--error-dsm-red, #E51F22)',
                                cursor: 'pointer',
                              }}
                              onClick={() => removeFile(file.name)}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </DsmGrid>
              <DsmGrid
                className={gridClasses.dsmGridthreeColumnWithPadding}
                style={{
                  padding: '30px 0px 0px 0px',
                }}
              >
                <ReactHookDsmInput
                  name="incidentLink"
                  label={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.LINK',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SUSTELL.HELP_CENTER.TECHNICAL_SUPPORT.FORM.INCIDENT.LINK.HINT',
                  })}
                />
              </DsmGrid>
            </div>
            <div className={classes.btnDiv}>
              <DsmButtonV2
                variant="contained"
                type="button"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  cancelChanges(e);
                }}
                style={{
                  background: 'var(--error-dsm-red, #E51F22)',
                  border: 'var(--error-dsm-red, #E51F22)',
                  '&:hover': {
                    backgroundColor: 'var(--error-dsm-red, #E51F22) !important',
                  },
                }}
              >
                <DsmIcon
                  slot="before"
                  name="general/trash-01"
                  style={{ paddingRight: '5px', height: '20px', width: '20px' }}
                />
                <span className={classes.saveBtnTxt}>
                  {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                </span>
              </DsmButtonV2>
              <div className="text-right">
                <DsmButtonV2
                  variant="contained"
                  disableElevation
                  type="submit"
                  disabled={
                    methods.formState.isSubmitting ||
                    (methods.formState.isDirty && !methods.formState.isValid)
                  }
                >
                  <span className={classes.saveBtnTxt}>
                    {intl.formatMessage({ id: 'GENERAL.SUBMIT' })}
                  </span>
                  <DsmIcon
                    slot="after"
                    name="arrows/arrow-right"
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      height: '25px',
                      width: '25px',
                      color: 'var(--neutrals-dsm-white, #FFF)',
                    }}
                  />
                </DsmButtonV2>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};
export default TechnicalSupport;
