import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmCheckbox, DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { unitLong } from '../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../Helpers/UserProfilePrefs';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../../sustell_15/components/CompoundFeedWizard/types';
import { masterDataSorter } from '../../../sustell_15/helpers/sortingFunctions';
import ReactHookDsmInput from '../../Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../Helpers/ReactHookDsmSelect2';
import { isShrimp } from '../../../sustell_15/helpers/animals';
import { StageType } from '../../../../graphql/types';
import { Tooltip } from '@material-ui/core';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';

const NewRationInput = (props) => {
  const {
    fieldItemPrefix,
    subFieldName,
    label,
    index,
    updateTotalFeedIntake,
    formState,
    availableFeedItems,
    removeHandler,
    animalType,
    stageType,
    isBeefFeed = false,
    displayMilkReplacerCheckbox = false,
  } = props;
  const { control, errors, getValues, setValue } = useFormContext();
  const intl = useIntl();
  const classes = processAndStageStylesV2();

  const resolvePath = (object, path, defaultValue) =>
    path
      .split(/[.[\]'"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object);
  const ftName = `${fieldItemPrefix}.${subFieldName}[${index}].feedType`;
  // console.log('ftName',ftName)
  const watch = useWatch({ control, name: ftName });
  // will not rename field itself just labels and placeholders for salmon, correct usage is handled by the mapper
  const name = `${fieldItemPrefix}.${subFieldName}[${index}].kgPerAnimal`;
  useEffect(
    () => () => {
      if (updateTotalFeedIntake) updateTotalFeedIntake(name, 0);
    },
    [name, updateTotalFeedIntake]
  );

  const localError = resolvePath(errors, name, null);
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  let selectedItemValue = getValues(ftName);
  selectedItemValue = selectedItemValue !== undefined ? selectedItemValue : '';
  let amountValue = getValues(name);
  amountValue = amountValue !== undefined ? amountValue : '';
  const labelKey = 'SUSTELL.AMOUNT';

  const getAmountTooltip = () => {
    if (isShrimp(animalType)) {
      return (stageType === StageType.Hatching 
        ? 'COMPOUND_FEED.AMOUNT.SHRIMP_HATCHERY.TOOLTIP' 
        : 'COMPOUND_FEED.AMOUNT.SHRIMP.TOOLTIP');
    }
    if (isBeefFeed) return 'COMPOUND_FEED.AMOUNT.BEEF.TOOLTIP';
    return 'COMPOUND_FEED.AMOUNT.TOOLTIP';
  }

  return (
    <>
      <DsmGrid
        style={{
          grid: 'auto/1fr 140px 32px',
          gap: 'var(--dsm-spacing-px-4)',
          alignItems: 'start',
          alignContent: ' space-between',
          padding: '0px',
        }}
      >
        <ReactHookDsmSelect
          key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart1`}
          label={label}
          name={ftName}
          defaultValue={selectedItemValue}
          disabled={formState === FormType.View}
          options={
            // eslint-disable-next-line react/destructuring-assignment
            availableFeedItems?.sort(masterDataSorter).map((compoundFeed) => ({
              value: compoundFeed.value,
              text: compoundFeed.display_name,
            }))
          }
        />
        <ReactHookDsmInput
          style={{ marginTop: '3px' }}
          key={`${fieldItemPrefix}.${subFieldName}[${index}]_inputPart2`}
          defaultValue={amountValue}
          name={name}
          label={intl.formatMessage({ id: labelKey })}
          adornment={
            userUOM?.unitBarnOutputMass
              ? unitLong(userUOM.unitBarnOutputMass)
              : 'kg'
          }
          helperText={localError && localError.message ? localError.message : ''}
          error={Boolean(localError)}
          disabled={formState === 'view' || !watch}
          tooltip={intl.formatMessage({
            id: getAmountTooltip(),
          })}
          changeHandler={() => {
            if (updateTotalFeedIntake) updateTotalFeedIntake(name, 0);
          }}
          type="number"
          required={!!watch}
        />
        {formState !== 'view' && (
          <DsmButton
            variant="text"
            iconOnly
            destructive
            onClick={removeHandler}
            style={{ padding: '0px', marginTop: '24px' }}
          >
            <DsmIcon slot="before" name="general/trash-01" />
          </DsmButton>
        )}
      </DsmGrid>
      {displayMilkReplacerCheckbox && (
        <div className={classes.resetButtonContainer}>
          <DsmCheckbox
            name={`${fieldItemPrefix}.milkReplacer`}
            defaultValue={
              getValues(`${fieldItemPrefix}.${subFieldName}[${index}].milkReplacer`)
            }
            checked={getValues(`${fieldItemPrefix}.${subFieldName}[${index}].milkReplacer`)}
            disabled={formState === FormType.View}
            onDsmChange={(e) => {
              setValue(`${fieldItemPrefix}.${subFieldName}[${index}].milkReplacer`, e?.target?.checked);
            }}
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.DAIRY.FEED.MILK_REPLACER`,
            })}
            style={{
              marginBottom: '17px',
            }}
          />
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.FEED.MILK_REPLACER',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(10%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};
export default NewRationInput;
