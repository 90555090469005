import { SurfaceType } from '../../../../graphql/generated/blonk/pigs';
import { AnimalType } from '../../../../graphql/types';

// TODO: This maybe needs changes later but there are multiple combinations of stages
// and animal types

const internalSource = {
  id: '',
  farmId: '',
  originStageId: '',
  originAnimalType: '', // used internally in input stage component to provide UI and enable visible validation
  numberAnimals: '',
  transportDistance: '',
};

const POULTRY_DEFAULTS = {
  copyFrom: 'New',
  info: {
    name: '',
    year: '',
    validFrom: '',
    validTo: '',
    description: '',
  },
  resourceUse: {
    electricityUse: '',
    dieselUse: '',
    gasUse: '',
    waterUse: '',
    gasolineUse: '',
    lngUse: '',
    coalUse: '',
  },
  stages: [
    {
      name: '',
      id: '',
      type: '',
      stageData: {
        input: {
          selected: true,
          henSelected: true,
          roosterSelected: true,
          interalSelected: true,
          externalSelected: false,
          selection: {
            internalSelected: true,
            externalSelected: false,
          },
          startDate: '',
          endDate: '',
          durationOfProductionCycle: '',
          emptyPeriod: '',
          animalsPresentAtStart: '',
          animalsPresentAtEnd: '',
          averageWeightAtStart: '',
          averageWeightAtEnd: '',
          originAnimalType: '', // this one is actually mapped on output
          internalSources: [{ ...internalSource }], // this is only used for the growing stages
          externalSources: [], // this is only used for the growing stages
          internalSource: { ...internalSource },
          externalSource: null,
          henInternalSource: { ...internalSource },
          henExternalSource: null,
          roosterInternalSource: { ...internalSource },
          roosterExternalSource: null,
        },
        output: {
          totalToStage: '',
          totalEggsToStage: '',
          totalHensToStage: '',
          averageWeight: '',
          averageEggsWeight: '',
          averageHensWeight: '',
          averageRoostersWeight: '',
          totalSold: '',
          totalEggsSold: '',
          totalHensSold: '',
          totalRoostersSold: '',
          price: '',
          priceEggs: '',
          priceHens: '',
          mortalityAtPercent: '',
          mortalityHensAtPercent: '',
          mortalityRoostersAtPercent: '',
          weightAtMortality: '',
          weightHensAtMortality: '',
          weightRoostersAtMortality: '',
          lossRate: '',
          priceOfLossesSold: '',
          totalEggShellsSold: '',
        },
        housing: {
          resourceUse: {
            electricityUse: '',
            dieselUse: '',
            gasUse: '',
            waterUse: '',
            gasolineUse: '',
            lngUse: '',
            coalUse: '',
          },
          housingType: '',
          timeInFreeRange: '',
          timeInHousing: '',
          manureSystems: [
            {
              localManureForm: '',
              mmsType: '',
              mmsHoldingDuration: '',
              share: '100',
            },
          ],
          beddingSystems: [
            {
              beddingType: '',
              beddingAmount: '',
            },
          ],
        },
        feed: {
          totalFeedIntake: '',
          compoundFeeds: [
            {
              feedType: '',
              kgPerAnimal: '',
            },
          ],
          singleIngredients: [
            {
              feedType: '',
              kgPerAnimal: '',
            },
          ],
          henFeed: {
            totalFeedIntake: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
          roosterFeed: {
            totalFeedIntake: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
        },
        emissions: {
          methane: '',
          nitrousOxideDirect: '',
          nitrousOxideIndirect: '',
          amonia: '',
          nitricOxide: '',
          nonMethaneVolatile: '',
          PM10: '',
          PM25: '',
        },
      },
    },
  ],
};

const MARINE_DEFAULTS = {
  copyFrom: 'New',
  info: {
    name: '',
    year: '',
    timeUnit: 'YEAR',
    numOfCyclesYear: 1,
    validFrom: '',
    validTo: '',
    description: '',
  },
  resourceUse: {
    electricityUse: '',
    waterUse: '',
  },
  stages: [
    {
      farmId: '',
      name: '',
      id: '',
      productionSystem: '',
      stageData: {
        output: {
          production: '',
          averageWeight: '',
          mortalities: '',
          escapees: '',
          animalBiomassStartOfYear: '',
          liveWeight: '',
          animalBiomasEndOfYear: '',
        },
        input: {
          numberOfStockedAnimals: '',
          averageLiveWeightStockedAnimals: '',
          weightOfStockedAnimals: '',
        },
        operations: {
          antifouling: '',
          materialsFarmConstruction: [
            {
              type: '',
              amount: '',
            },
          ],
          liceTreatment: [
            {
              type: '',
              amount: '',
            },
          ],
          transport: [
            {
              boatName: '',
              type: '',
              amount: '',
            },
          ],
        },
        feed: {
          totalFeedIntake: '',
          compoundFeeds: [
            {
              feedType: '',
              kgPerAnimal: '',
            },
          ],
          antibioticsUse: [
            {
              type: '',
              amount: '',
            },
          ],
          nutrientComposition: {
            crudeProtein: '',
            crudeLipid: '',
            crudeFibre: '',
            starch: '',
            sugar: '',
            phosphorous: '',
            feedSpill: '5',
            dryMatter: '',
            solubleNitrogen: '15',
            solublePhosphorous: '15',
            solubleCarbon: '15',
            digestibleNitrogen: '85',
            digestibleCarbon: '80',
            digestiblePhosphorous: '50',
          },
        },
      },
    },
  ],
};

const SHRIMP_DEFAULTS = {
  copyFrom: 'New',
  info: {
    name: '',
    year: '',
    validFrom: '',
    validTo: '',
    description: '',
    age: ''
  },
  resourceUse: {
    electricityUse: '',
    dieselUse: '',
    gasUse: '',
    waterUse: '',
    gasolineUse: '',
    lngUse: '',
    coalUse: '',
  },
  stages: [
    {
      name: '',
      id: '',
      type: '',
      pondName: '',
      stageData: {
        animalProductivity: 'HIGH',
        input: {
          totalPondArea: '',
          emptyPeriod: '',
          stageDuration: '',
          totalWeightAtStartDate: '',
          totalWeightAtEndDate: '',
          priceAtStartDate: '',
          priceAtEndDate: '',
          selection: {
            internalSelected: true,
            externalSelected: true,
          },
          internalSources: [
            {
              comingFromFarm: '',
              comingFromStage: '',
              outputName: '',
              totalWeight: '',
              distanceTraveled: '',
              transportMode: '',
            },
          ],
          externalSources: [
            {
              totalWeight: '',
              distanceTraveled: '',
              transportMode: '',
            },
          ],
        },
        output: {
          outputs: [{
            calculateLca: true,
            name: '',
            price: '',
            soldExternally: 'Yes',
            totalWeight: '',
            proteinContent: '',
          }]
        },
        feed: {
          totalFeedIntake: '',
          compoundFeeds: [
            {
              feedType: '',
              kgPerAnimal: '',
            },
          ],
          singleIngredients: [
            {
              feedType: '',
              kgPerAnimal: '',
              origin: '',
            },
          ],
          naturalFeed: [
            {
              type: '',
              quantity: '',
              origin: '',
              crudeProteinContent: undefined,
            },
          ],
        },
        operations: {
          resourceUse: {
            electricityUse: '',
            gasUse: '',
            waterUse: '',
            watersalinity: '',
            waterEnteringPondIn: '',
            nitrogenConcentrationIn: '',
            waterEnteringPondOut: '',
            nitrogenConcentrationOut: '',
          },
          fertilizerTypes: [
            {
              type: '',
              amount: '',
            },
          ],
          materialTypes: [
            {
              type: '',
              amount: '',
              transportMode: '',
              distanceTraveled: '',
              lifetimeOfMaterial: '',
            },
          ],
          chemicalTypes: [
            {
              type: '',
              amount: '',
            },
          ],
          iceAmount: '',
        },
        emissions: {
          nitrogenToWater: '',
        },
      },
    },
  ],
};

const BEEF_STAGE_HOUSING = {
  resourceUse: {
    electricityUse: '',
    dieselUse: '',
    gasUse: '',
    waterUse: '',
    gasolineUse: '',
    lngUse: '',
    coalUse: '',
  },
  housingType: '',
  timeInFreeRange: '',
  timeInHousing: '',
  manureSystems: [
    {
      localManureForm: '',
      mmsType: '',
      mmsHoldingDuration: '',
      share: '100',
    },
  ],
  beddingSystems: [
    {
      beddingType: '',
      beddingAmount: '',
    },
  ],
};

const BEEF_BREEDING_STAGE_HOUSING = {
  cows: BEEF_STAGE_HOUSING,
  calves: BEEF_STAGE_HOUSING,
  beddingSystems: [
    {
      beddingType: '',
      beddingAmount: '',
    },
  ],
};

const BEEF_STAGE_EMISSIONS = {
  methaneEntericFermentation: '',
  NMVOC: '',
  methaneMms: '',
  nitrousOxideLeaching: '',
  nitrousOxideDirect: '',
  nitrousOxideVolatilization: '',
  nitrogenOxidesFromManureStorage: '',
  amoniaStorage: '',
  amoniaHousing: '',
  amoniaYard: '',
  dinitrogenStorage: '',
  PM2_5: '',
  PM10: '',
  TSP: '',
  NOP: '',
};

const BEEF_BREEDING_STAGE_EMISSIONS = {
  cows: BEEF_STAGE_EMISSIONS,
  calves: BEEF_STAGE_EMISSIONS,
};

export const beefAshContentValue = 17.9

const BEEF_STAGE_FEED = {
  totalFeedIntake: '',
  compoundFeeds: [
    {
      feedType: '',
      kgPerAnimal: '',
    },
  ],
  singleIngredients: [
    {
      feedType: '',
      kgPerAnimal: '',
    },
  ],
  freshGrass: [
    {
      grossEnergyContent: '',
      urinaryEnergy: '',
      ashContent: beefAshContentValue,
    },
  ],
};

const BEEF_BREEDING_STAGE_FEED = {
  cows: BEEF_STAGE_FEED,
  calves: {
    totalFeedIntake: '',
    averageMilkFat: 4,
    compoundFeeds: [
      {
        feedType: '',
        kgPerAnimal: '',
      },
    ],
    singleIngredients: [
      {
        feedType: '',
        kgPerAnimal: '',
      },
    ],
    freshGrass: [
      {
        grossEnergyContent: '',
        urinaryEnergy: '',
        ashContent: beefAshContentValue,
      },
    ],
  },
};

export const dairyAshContentValue = 17.9
export const dairyGrossEnergyContentValue = 18.45

export const defaultValues = {
  LAYING_HENS: {
    animalType: AnimalType.LayingHens,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      avgAnualTemperature: '',
      timeUnit: 'ROUND',
      numOfCyclesYear: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: 'Aviary',
        stageData: {
          output: {
            eggs: '',
            liveWeight: '',
          },
          input: {
            purchasedAnimalsLayingHens: '',
            mortalityRate: '',
            averageLiveWeightMortalities: '',
          },
          manure: {
            averageAnimalsPresent: '',
            manureManagementSystem: '',
            solidManureStorage: '',
            slurryManureStorage: '',
            ashContent: '',
            strawForBedding: '',
            sawDust: '',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            nitrogenContent: '',
            dryMatterContent: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
          emissions: {
            methane: '',
            nitrousOxideDirect: '',
            nitrousOxideIndirect: '',
            amonia: '',
            nitricOxide: '',
            nonMethaneVolatile: '',
            PM10: '',
            PM25: '',
            totalSuspendedParticles: '',
          },
        },
      },
    ],
  },
  BROILER: {
    animalType: AnimalType.Broiler,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      avgAnualTemperature: '',
      timeUnit: 'ROUND',
      numOfCyclesYear: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          output: {
            liveWeight: '',
          },
          input: {
            purchasedOneDayChickens: '',
            mortalityRate: '',
            averageLiveWeightMortalities: '',
          },
          manure: {
            averageAnimalsPresent: '',
            manureManagementSystem: '',
            solidManureStorage: '',
            slurryManureStorage: '',
            ashContent: '',
            strawForBedding: '',
            sawDust: '',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            nitrogenContent: '',
            dryMatterContent: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
          emissions: {
            methane: '',
            nitrousOxideDirect: '',
            nitrousOxideIndirect: '',
            amonia: '',
            nitricOxide: '',
            nonMethaneVolatile: '',
            PM10: '',
            PM25: '',
            totalSuspendedParticles: '',
          },
        },
      },
    ],
  },
  PIG_FATTENING: {
    animalType: AnimalType.Pig,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      avgAnualTemperature: '',
      timeUnit: 'ROUND',
      numOfCyclesYear: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          output: {
            liveWeight: '',
            inorganicNFertilizerReplacementRate: '',
            inorganicPFertilizerReplacementRate: '',
          },
          input: {
            purchasedPiglets: '',
            averageLiveWeight: '',
            mortalityRate: '',
            averageLiveWeightMortalities: '',
          },
          manure: {
            averageAnimalsPresent: '',
            manureManagementSystem: '',
            solidManureStorage: '',
            slurryManureStorage: '',
            strawForBedding: '',
            sawDust: '',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            grossEnergyIntake: '',
            nitrogenContent: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
          emissions: {
            methaneEntericFermentation: '',
            methane: '',
            nitrousOxideDirect: '',
            nitrousOxideIndirect: '',
            amonia: '',
            nitricOxide: '',
            nonMethaneVolatile: '',
            PM10: '',
            PM25: '',
            totalSuspendedParticles: '',
            ammoniaHousing: '',
          },
        },
      },
    ],
  },
  PIG: {
    animalType: AnimalType.Pig,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
      gasolineUse: '',
      lngUse: '',
      coalUse: '',
    },
    stages: [
      {
        name: '',
        id: '',
        type: '',
        facilityId: '',
        stageData: {
          animalProductivity: 'HIGH',
          input: {
            startDate: '',
            endDate: '',
            pigsPresentAtStart: '',
            pigsPresentAtEnd: '',
            originAnimalType: '', // this one is actually mapped on output
            internalSources: [
              {
                id: '',
                numberOfNewPigs: '',
                originStageId: '',
                distanceTransport: '',
                hasBreedingInput: 'false', // used internally in input stage component to enable visible validation
                originAnimalType: '', // used internally in input stage component to provide UI and enable visible validation
              },
            ],
            externalSources: [
              {
                id: '',
                numberOfNewPigs: '',
                averageAgeOfPigs: '',
                averageWightOfPigs: '',
                distanceTransport: '',
              },
            ],
            optionalInput: {
              pigsPresentAtStart: '',
              pigsPresentAtEnd: '',
              averageWeightPigsStart: '',
              averageWeightPigsEnd: '',
              averageAgePigsStart: '',
              averageAgePigsEnd: '',
            },
          },
          output: {
            averageWeightPigs: '',
            averageWeightPiglets: '',
            mortalityPigs: '',
            mortalityPiglets: '',
            pigsToStage: '',
            pigletsToStage: '',
            soldPigs: '',
            soldPiglets: '',
            averageAgePigs: '',
            ageAtMortalityPigs: '',
            priceSoldPigs: '',
            priceSoldPiglets: '',
            weightAtMortalityPigs: '',
            weightAtMortalityPiglets: '',
          },
          housing: {
            surfaceType: SurfaceType.SlattedFloor,
            manureSystems: [
              {
                localManureForm: '',
                mmsType: '',
                mmsHoldingDuration: '',
                share: '100',
              },
            ],
            beddingSystems: [
              {
                beddingType: '',
                beddingAmount: '',
              },
            ],
            materials: [
              {
                materialType: '',
                materialAmount: '',
              },
            ],
          },
          feed: {
            totalFeedIntake: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
                origin: '',
              },
            ],
          },
          emissions: {
            methaneEntericFermentation: '',
            methane: '',
            nitrousOxideDirect: '',
            nitrousOxideIndirect: '',
            amonia: '',
            nitricOxide: '',
            nonMethaneVolatile: '',
            PM10: '',
            PM25: '',
            totalSuspendedParticles: '',
            ammoniaHousing: '',
          },
          // facilityPackaging: {
          //   primaryPackaging: [
          //     {
          //       packagingType: '',
          //       packagingAmount: ''
          //     }
          //   ],
          //   tertiaryPackaging: [
          //     {
          //       packagingType: '',
          //       packagingAmount: ''
          //     }
          //   ]
          // }
        },
      },
    ],
  },
  LITOPENAEUS_VANNAMEI: {
    animalType: AnimalType.LitopenaeusVannamei,
    ...SHRIMP_DEFAULTS,
  },
  PENAEUS_MONODON: {
    animalType: AnimalType.PenaeusMonodon,
    ...SHRIMP_DEFAULTS,
  },
  DAIRY: {
    animalType: AnimalType.Dairy,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      avgAnualTemperature: '',
      timeUnit: 'YEAR',
      numOfCyclesYear: 1,
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          output: {
            milk: '',
            proteinContent: '',
            fatContent: '',
            liveWeight: '',
          },
          manure: {
            averageAnimalsPresent: '',
            manureManagementSystem: '',
            solidManureStorage: '',
            slurryManureStorage: '',
            strawForBedding: '',
            sawDust: '',
          },
          feed: {
            totalFeedIntake: '',
            timeSpentGrazing: '',
            timeSpentInOpenYardAreas: '',
            timeSpentInBuildings: '',
            digestibility: '',
            grossEnergyIntake: '',
            crudeProteinContent: '',
            percentageOfSilage: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
          },
          emissions: {
            methaneEntericFermentation: '',
            methane: '',
            nitrousOxideDirect: '',
            nitrousOxideIndirect: '',
            amonia: '',
            nitricOxide: '',
            nonMethaneVolatile: '',
            nonMethaneVolatileSilage: '',
            PM25: '',
            is3NOPSelected: false,
            supplement3NOP: {
              _3nopDosePerCowPerDay: '',
              dmiAmountPerKg: '',
              fatAmountPercentage: '',
              ndfAmountPercentage: '',
              numberOfCowsCovered: '',
              calculatedResult: '',
            },
          },
        },
      },
    ],
  },
  SALMON: {
    animalType: AnimalType.Salmon,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      timeUnit: 'YEAR',
      numOfCyclesYear: 1,
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      waterUse: '',
      waterSource: '',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          output: {
            production: '',
            averageWeight: '',
            mortalities: '',
            escapees: '',
            animalBiomassStartOfYear: '',
            liveWeight: '',
            animalBiomasEndOfYear: '',
          },
          input: {
            numberOfStockedAnimals: '',
            averageLiveWeightStockedAnimals: '',
            weightOfStockedAnimals: '',
          },
          operations: {
            antifouling: '',
            materialsFarmConstruction: [
              {
                type: '',
                amount: '',
              },
            ],
            anitibioticsUse: [
              {
                type: '',
                amount: '',
              },
            ],
            liceTreatment: [
              {
                type: '',
                amount: '',
              },
            ],
            transport: [
              {
                boatName: '',
                type: '',
                amount: '',
              },
            ],
          },
          feed: {
            totalFeedIntake: '',
            efcr: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            requiredNutritionalContent: {
              crude_protein: '',
              crude_lipid: '',
              crude_fibre: '',
              starch: '',
              sugar: '',
              phosphorus: '',
              dm_feed: '',
            },
            optionalNutritionalContent: {
              gross_energy: '',
              digestible_energy: '',
              digestible_nitrogen: 85,
              digestible_phosphorus: 50,
              digestible_carbon: 80,
              soluble_nitrogen: 15,
              soluble_phosphorus: 15,
              soluble_carbon: 15,
              feed_spill: 5,
            },
          },
        },
      },
    ],
  },
  SALMON_V2: {
    animalType: AnimalType.SalmonV2,
    ...MARINE_DEFAULTS,
  },
  SEA_BASS: {
    animalType: AnimalType.SeaBass,
    ...MARINE_DEFAULTS,
  },
  SEA_BREAM: {
    animalType: AnimalType.SeaBream,
    ...MARINE_DEFAULTS,
  },
  BROILER_V2: {
    animalType: AnimalType.BroilerV2,
    ...POULTRY_DEFAULTS,
  },
  LAYING_HENS_V2: {
    animalType: AnimalType.LayingHensV2,
    ...POULTRY_DEFAULTS,
  },
  TURKEY: {
    animalType: AnimalType.Turkey,
    ...POULTRY_DEFAULTS,
  },
  BEEF: {
    animalType: AnimalType.Beef,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
      gasolineUse: '',
      lngUse: '',
      coalUse: '',
    },
    stages: [
      {
        name: '',
        id: '',
        type: '',
        stageData: {
          input: {
            startDate: '',
            endDate: '',
            isStageRepeated: 'No',
            cattleProductivity: 'LOW',
            animalsPresentAtStart: '',
            animalsPresentAtEnd: '',
            averageWeightAtStart: '',
            averageWeightNewAnimals: '',
            numberOfRepetitions: 0,
            growingPurpose: '',
            averageAgeAtStart: '',
            internalSources: [
              {
                id: '',
                farmId: '',
                originStageId: '',
                animalType: '',
                numberAnimals: '',
                distanceTruck: '',
                shrinkingRate: 0,
              },
            ],
            externalSources: [
              {
                id: '',
                origin: '',
                numberAnimals: '',
                distanceTruck: '',
                shrinkingRate: 0,
              },
            ],
            averageWeightAtBirth: 40,
            permanencePeriod: '',
          },
          output: {
            cowsToAnotherStage: '',
            averageWeightOfCowsLeavingTheStage: '',
            cowsSold: '',
            averageAge: '',
            numberOfMortalitiesCows: '',
            averageWeightAtMortalityCows: '',
            weanedCalvesToAnotherStage: '',
            averageWeightOfCalvesLeavingTheStage: '',
            weanedCalvesSold: '',
            numberOfMortalitiesCalves: '',
            averageWeightAtMortalityCalves: '',
          },
          housing: {
            ...BEEF_STAGE_HOUSING,
            ...BEEF_BREEDING_STAGE_HOUSING,
          },
          feed: {
            ...BEEF_STAGE_FEED,
            ...BEEF_BREEDING_STAGE_FEED,
          },
          emissions: {
            ...BEEF_STAGE_EMISSIONS,
            ...BEEF_BREEDING_STAGE_EMISSIONS,
          },
        },
      },
    ],
  },
  DAIRY_V2: {
    animalType: AnimalType.DairyV2,
    copyFrom: 'New',
    info: {
      name: '',
      year: '',
      validFrom: '',
      validTo: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      dieselUse: '',
      gasUse: '',
      waterUse: '',
      gasolineUse: '',
      lngUse: '',
      coalUse: '',
    },
    stages: [
      {
        name: '',
        id: '',
        type: '',
        stageData: {
          input: {
            startDate: '',
            cattleProductivity: 'HIGH',
            animalsPresentAtStart: '',
            animalsPresentAtEnd: '',
            averageWeightAtStart: '',
            averageWeightAtEnd: '',
            averageAge: '',
            averageWeightNewAnimals: '',
            internalSources: [
              {
                id: '',
                farmId: '',
                originStageId: '',
                animalType: '',
                numberAnimals: '',
                distanceTruck: '',
                weightLoss: 0,
              },
            ],
            externalSources: [
              {
                id: '',
                origin: '',
                numberAnimals: '',
                distanceTruck: '',
                weightLoss: 0,
              },
            ],
            averageWeightAtBirth: 40,
          },
          output: {
            cowsToAnotherStage: '',
            averageWeightOfCowsLeavingTheStage: '',
            cowsSold: '',
            numberOfMortalitiesCows: '',
            averageWeightAtMortalityCows: '',
            weanedCalvesToAnotherStage: '',
            weanedCalvesSold: '',
            yearlyMilkProduction: '',
            averageMilkFat: 4,
            averageMilkProtein: '',
          },
          housing: {
            ...BEEF_STAGE_HOUSING,
            ...BEEF_BREEDING_STAGE_HOUSING,
          },
          feed: {
            totalFeedIntake: '',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
              },
            ],
            freshGrass: [
              {
                type: '',
                amount: '',
                grossEnergyContent: dairyGrossEnergyContentValue,
                ashContent: dairyAshContentValue,
              },
            ],
          },
          emissions: {
            ...BEEF_STAGE_EMISSIONS,
            ...BEEF_BREEDING_STAGE_EMISSIONS,
          },
        },
      },
    ],
  },
};
