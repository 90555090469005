/* eslint-disable import/no-extraneous-dependencies */
import { FC, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmGrid } from '@dsm-dcs/design-system-react';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import { unitLong } from '../../../../utils/unit-utils';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { StageType } from '../../../../../../graphql/types';
import { BeddingType } from '../../../../../../graphql/generated/blonk/beef';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2ContinuousGrowingStageData,
  DairyV2Housing,
  DairyV2ManureManagementSystemType,
  ManureManagementSystem,
  StoragePeriod,
  TargetAnimal,
} from '../../../../models/Baseline/DairyV2Baseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

import BeddingInputComponent from '../../CommonDataParts/BeddingInputComponent';
import DairyV2HousingComponent from './DairyV2HousingComponent';
import DairyV2MMSComponent from './DairyV2MMSComponent';
import WarningDialog from '../../../helpers/WarningDialog';

export interface DairyV2HousingAndManureDialogProps extends BaselineDialogProps {
  stageType: string;
}

const DairyV2HousingAndManureDialog: FC<DairyV2HousingAndManureDialogProps> = ({
  formVisible,
  itemIndex = 0,
  formType = FormType.Add,
  handleCancel,
  stageType,
}) => {
  const intl = useIntl();
  const [isDescriptionDialogVisible, setIsDescriptionDialogVisible] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";
  const formTitle = intl.formatMessage(
    { id: "SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE" },
    { br: " " }
  );
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<DairyV2Housing>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const formContext = useFormContext<DairyV2Baseline>();

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<DairyV2Housing>(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyStageData = resetObject.stages[itemIndex]?.stageData as
        | DairyV2ContinuousGrowingStageData
        | DairyV2DairyCowStageData;
      if (dairyStageData?.housing) {
        dairyStageData.housing = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel("reset");
  };

  const getBeddingOptions = () => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      "SUSTELL.DAIRY.ENUMS.BEDDING_TYPE"
    );
    const defaultOption = { text: "No bedding", value: "" };
    return [defaultOption].concat(beddings);
  };

  const isMMSTypeSelectionDisabled = (targetAnimal?: TargetAnimal): boolean => {
    const housingFormValuesPrefix = targetAnimal
      ? `${fieldItemPrefix}.${targetAnimal}.timeInHousing`
      : `${fieldItemPrefix}.timeInHousing`;
    const mmsTypeFormValuesPrefix = targetAnimal
      ? `${fieldItemPrefix}.${targetAnimal}.manureSystems`
      : `${fieldItemPrefix}.manureSystems`;
    const mmsTypeValues: Array<ManureManagementSystem> = formContext.getValues(
      mmsTypeFormValuesPrefix
    );
    const mmsIsNotEmpty = mmsTypeValues.every((mms) => Boolean(mms.mmsType));
    const timeInHousing: number = formContext.watch(housingFormValuesPrefix);
    const hasNoHousingTime = timeInHousing <= 0;

    if (mmsIsNotEmpty && hasNoHousingTime)
      formContext.setValue(mmsTypeFormValuesPrefix, []);
    return hasNoHousingTime || formType === FormType.View;
  };

  const getMMSTypeOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      DairyV2ManureManagementSystemType,
      intl,
      "SUSTELL.DAIRY.ENUMS.MMS_TYPE"
    );

  const getMMSStorageOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      StoragePeriod,
      intl,
      "SUSTELL.DAIRY.ENUMS.MMS_STORAGE"
    );

  const closeDescriptionDialog = () => setIsDescriptionDialogVisible(false);
  const openDescriptionDialog = () => setIsDescriptionDialogVisible(true);

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="ultrawide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
    >
      {isDescriptionDialogVisible && (
        <WarningDialog
          isOpen
          closeModal={() => closeDescriptionDialog()}
          title={'SUSTELL.STAGE.DAIRY.MMS.MMS_TYPE'}
          body={'SUSTELL.STAGE.DAIRY.DEFINITIONS.COMING_SOON'}
        />
      )}

      <BeddingInputComponent
        isViewMode={formType === FormType.View}
        beddingAmoutWeightUnit={barnOutputMassUnit}
        beddingOptions={getBeddingOptions()}
        stageIndex={itemIndex}
        beddingAmountTooltipId="SUSTELL.STAGE.DAIRY.BEDDING.AMOUNT.TOOLTIP"
      />

      <p className={classes.headingTitle}>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.DAIRY.MMS.DESCRIPTION",
        })}
      </p>

      <DsmGrid className={classes.dsmGridTwoColumn2nd45NoGap}>
        <DairyV2HousingComponent
          stageIndex={itemIndex}
          isViewMode={formType === FormType.View}
        />
        <div className={classes.MMSlightBlueBoxContainer}>
          <DairyV2MMSComponent
            stageIndex={itemIndex}
            mmsOptions={getMMSTypeOptions()}
            storageOptions={getMMSStorageOptions()}
            openDescriptionDialog={openDescriptionDialog}
            isViewMode={isMMSTypeSelectionDisabled()}
            isDairyCowStage={stageType === StageType.DairyCow}
          />
        </div>
      </DsmGrid>

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.housing`);
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default DairyV2HousingAndManureDialog;
