import { v4 as uuidv4 } from 'uuid';
import {
  FacilityOutputNames,
  Packaging,
  ProcessingInput,
  ProcessingOutput,
  ProcessingPackaging,
  ProcessingPackagingItem,
  ProcessingStage,
  TransportTypeDistance,
} from '../../../../sustell_15/models/Facility/Processing';
import {
  AnimalOutputType,
  PackagingDBModel,
  ProcessingInputDBModel,
  ProcessingOutputDBModel,
  ProcessingPackagingDBModel,
  ProcessingPackagingItemDBModel,
  ProcessingStageDBModel,
  StageType,
  TransportDBModel,
} from '../../../../sustell_15/models/TempBackendModels/TempProcessingDB';
import {
  formatMassInput,
  formatTransportInput,
  removeNullOrUndefined,
} from './outMapperHelpers';
import {
  PrimaryPackagingType,
  SecondaryPackagingType,
  TertiaryPackagingType,
} from '../../../../../graphql/types';

export const TRANSPORT_DISTANCE_UOM_MAP = {
  INLAND_SHIP: 'unitTransportDistanceInlandWaterways',
  SEA_SHIP: 'unitTransportDistanceSea',
  TRAIN: 'unitTransportDistanceTerrestrial',
  TRUCK: 'unitTransportDistanceTerrestrial',
};

const mapTransportType = (
  incomingData?: Array<TransportTypeDistance> | null
): Array<TransportDBModel> => {
  if (!incomingData || !incomingData.length) return [];

  return incomingData
    .filter((item) => item.distance && item.transportMode)
    .map((item) => ({
      transportMode: item.transportMode,
      distance: formatTransportInput(item.distance, item.transportMode),
    }));
};

const mapProcessingInput = (
  incomingData: ProcessingInput
): ProcessingInputDBModel => {
  if (!incomingData) return {} as ProcessingInputDBModel;

  const mappedProcessingInput: ProcessingInputDBModel = {
    originStageId: incomingData.originStageId || '',
    originStageType: incomingData?.stageType as StageType || null,
    averageWeight: formatMassInput(incomingData.averageWeight),
    name: (incomingData.name || '') as AnimalOutputType,
    quantity: Number(incomingData.quantity),
    transport: mapTransportType(incomingData.transport),
    animalType: incomingData?.animalType || null,
    shrinkingRate: Number(incomingData?.shrinkingRate) || null
  };

  return mappedProcessingInput;
};

export const OUTPUT_PACKAGING_PROPS: Array<FacilityOutputNames> = [
  FacilityOutputNames.FRESH_MEAT_AND_EDIBLE_OFFAL,
  FacilityOutputNames.FOOD_GRADE_BONES,
  FacilityOutputNames.FOOD_GRADE_FAT,
  FacilityOutputNames.CAT1_AND2_MATERIAL_AND_WASTE,
  FacilityOutputNames.CAT3_SLAUGHTER_BY_PRODUCTS,
  FacilityOutputNames.CAT3_HIDES_AND_SKINS,
];

const mapProcessingOutput = (
  incomingData: ProcessingOutput
): ProcessingOutputDBModel => {
  if (!incomingData) return {} as ProcessingOutputDBModel;

  return OUTPUT_PACKAGING_PROPS.reduce(
    (acc: ProcessingOutputDBModel, packagingProp: FacilityOutputNames) => {
      const packagingItem = incomingData[packagingProp];

      if (!packagingItem) return acc;

      acc[packagingProp] = {
        price: Number(packagingItem.price),
        weight: formatMassInput(packagingItem.weight),
        calculate:
          packagingProp === FacilityOutputNames.FRESH_MEAT_AND_EDIBLE_OFFAL
            ? true
            : Boolean(packagingItem?.calculate),
      };

      return acc;
    },
    {}
  );
};

const mapPackagingItem = (
  incomingData: Array<ProcessingPackagingItem<unknown>>
): Array<ProcessingPackagingItemDBModel<unknown>> | null => {
  if (!incomingData || !incomingData.length) return null;

  const mappedItem = incomingData
    .filter((packagingItem) => packagingItem.type && packagingItem.amount)
    .map((packagingItem) => ({
      type: packagingItem.type,
      amount: formatMassInput(packagingItem.amount),
    }));

  return mappedItem;
};

const mapPackagingSet = (incomingData: Packaging): PackagingDBModel => {
  if (!incomingData) return {} as PackagingDBModel;

  let mappedData: PackagingDBModel = {};

  if (incomingData?.primary && incomingData?.primary?.length) {
    mappedData = {
      ...mappedData,
      primary: mapPackagingItem(incomingData.primary) as Array<
        ProcessingPackagingItemDBModel<PrimaryPackagingType>
      >,
    };
  }

  if (incomingData?.secondary) {
    mappedData = {
      ...mappedData,
      secondary: mapPackagingItem([incomingData.secondary]) as Array<
        ProcessingPackagingItemDBModel<SecondaryPackagingType>
      >,
    };
  }

  if (incomingData?.tertiary && incomingData?.tertiary?.length) {
    mappedData = {
      ...mappedData,
      tertiary: mapPackagingItem(incomingData.tertiary) as Array<
        ProcessingPackagingItemDBModel<TertiaryPackagingType>
      >,
    };
  }

  return mappedData;
};

const mapProcessingPackaging = (
  incomingData: ProcessingPackaging
): ProcessingPackagingDBModel => {
  if (!incomingData) return {} as ProcessingPackagingDBModel;

  const mappedData = OUTPUT_PACKAGING_PROPS.reduce(
    (acc: ProcessingPackagingDBModel, packagingProp: FacilityOutputNames) => {
      const packagingItem = incomingData[packagingProp];
      if (!packagingItem) return acc;

      acc[packagingProp] = mapPackagingSet(packagingItem);
      return acc;
    },
    {}
  );

  return mappedData;
};

export const mapOutProcessingStage = (
  incomingData: ProcessingStage
): ProcessingStageDBModel => {
  if (!incomingData) return {} as ProcessingStageDBModel;

  try {
    const mappedStage: ProcessingStageDBModel = {
      facilityId: incomingData.facilityId,
      type: (incomingData.type as StageType) || StageType.Processing,
      name: incomingData.name,
      id: incomingData?.id || uuidv4(),
      stageData: {
        processingInput: mapProcessingInput(
          incomingData.stageData.processingInput
        ),
        processingOutput: mapProcessingOutput(
          incomingData.stageData.processingOutput
        ),
        processingPackaging: mapProcessingPackaging(
          incomingData.stageData.processingPackaging
        ),
      },
    };
    
    return removeNullOrUndefined<ProcessingStageDBModel>(mappedStage);
  } catch(err) {
    console.error('Error on mapOutProcessingStage: ', err)
  }

  return {} as ProcessingStageDBModel;
};
