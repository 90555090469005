/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

interface DairyV2InputPartExternalSourceProps {
  stageIndex: number;
  itemIndex: number;
  propName: string;
  formType: FormType;
  removeHandler: (index: number) => void;
}

const DairyV2InputPartExternalSource: FC<DairyV2InputPartExternalSourceProps> = ({
  stageIndex,
  itemIndex,
  propName = 'externalSources',
  formType,
  removeHandler,
}) => {
  const intl = useIntl();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const distanceUnit = unitLong(
    userUOM.unitTransportDistanceTerrestrial as string
  );
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fc = useFormContext();
  const fieldItemPrefix =
    itemIndex || itemIndex === 0
      ? `stages.${stageIndex}.stageData.input.${propName}.${itemIndex}`
      : `stages.${stageIndex}.stageData.input.${propName}`;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={fc.getValues(`${fieldItemPrefix}.id`) as string}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => removeHandler(itemIndex)}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberAnimals`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE`,
          })}
          adornment={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.ADORNMENT`,
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={fc.getValues(`${fieldItemPrefix}.numberAnimals`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTruck`}
          type="number"
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK`,
          })}
          adornment={distanceUnit}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
          })}
          defaultValue={fc.getValues(`${fieldItemPrefix}.distanceTruck`)}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.weightLoss`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS',
          })}
          disabled={formType === FormType.View}
          adornment="%"
          type="number"
          tooltip={intl.formatMessage(
            {
              id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP`,
            }
          )}
          defaultValue={fc.getValues(`${fieldItemPrefix}.weightLoss`)}
        />
      </DsmGrid>
    </>
  );
};

export default DairyV2InputPartExternalSource;
