/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sortedUniqBy } from 'lodash';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  DatabaseFoundation,
  InputAnimalOrigin,
} from '../../../../../../graphql/generated/blonk/pigs';
import {
  CSSClassesList,
  ReactChangedType,
} from '../../../../helpers/helperTypes';
import { FormType, ListEntry, DairyV2Farms } from '../../common';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import {
  DairyV2Baseline,
  DairyV2Stage,
  InternalSource,
} from '../../../../models/Baseline/DairyV2Baseline';

interface DairyV2InputPartInternalSourceProps {
  farms: DairyV2Farms[];
  farmId: string;
  farmName: string;
  allowedStagesForFarm: string[];
  productionProcessName: string;
  stageIndex: number;
  // eslint-disable-next-line react/require-default-props
  itemIndex: number;
  formType: FormType;
  fieldPrefix: string;
  // eslint-disable-next-line react/require-default-props
  removeHandler: (index: number) => void;
  // eslint-disable-next-line react/require-default-props
  triggerSelectionError?: boolean;
}

const DairyV2InputPartInternalSource: FC<DairyV2InputPartInternalSourceProps> = ({
  farms,
  farmId,
  farmName,
  allowedStagesForFarm,
  productionProcessName,
  stageIndex,
  itemIndex,
  fieldPrefix,
  formType,
  removeHandler,
  triggerSelectionError = false,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formContext = useFormContext<DairyV2Baseline>();
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const distanceUnit = unitLong(
    userUOM.unitTransportDistanceTerrestrial as string
  );
  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = formContext.getValues(
      `info.databaseFoundation`
    );
    if (!initValue)
      initValue = userProfile.getUserDatabasePrefs()
        .databaseType as DatabaseFoundation;
    return initValue;
  };
  const fieldItemPrefix = `${fieldPrefix}.internalSources.${itemIndex}`;
  let selectedFarmId = '';
  useWatch({ name: `${fieldItemPrefix}` });

  const invalidateStageSelection = (e: ReactChangedType) => {
    formContext.setValue(`${fieldItemPrefix}.farmName`, e.target.name);
    const newSelection: string = e.target.value as string;
    if (newSelection !== selectedFarmId) {
      formContext.setValue(`${fieldItemPrefix}.originStageId`, '');
      formContext.setValue(`${fieldItemPrefix}.averageWeight`, '');
      selectedFarmId = newSelection;
    }
  };

  const addCurrentFarm = (resultFarms: ListEntry[]) => {
    const currentStage: DairyV2Stage = formContext.getValues(
      `stages.${stageIndex}`
    );
    const currentFarm = resultFarms.find((farm) => farm.value === farmId);
    const stages = formContext
      .getValues('stages')
      .filter((stage: DairyV2Stage) =>
        allowedStagesForFarm.includes(stage.type ?? '')
      )
      .filter((stage: DairyV2Stage) => currentStage?.id !== stage.id);
    // If there are more then one stage on current opened baseline only then add the current farm and db
    if (stages?.length > 0 && currentFarm === undefined)
      resultFarms.push({ text: farmName, value: farmId });
  };

  const farmOptions = () => {
    // Filter out the Farm that are not relevant
    const resultFarms = farms
      .filter(
        (farm) =>
          farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation
      )
      .filter((farm) => allowedStagesForFarm.includes(farm.stageType))
      .map((farm) => ({ text: farm.farmName, value: farm.farmId }));
    // Add farm for currently opened stages
    addCurrentFarm(resultFarms);
    // Because a farm can have multiple processes and baselines, its entry can be duplicated
    return sortedUniqBy(resultFarms, 'value');
  };

  const generateSelectedIds = (): string[] => {
    const sources: InternalSource[] = formContext.getValues(
      `stages.${stageIndex}.stageData.input.internalSources`
    );
    return sources?.map(
      (internalSources: InternalSource) => internalSources.originStageId
    );
  };

  const stageOptions = () => {
    const currentlySelectedFarm: string =
      formContext.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const currentlySelectedStage: string = formContext.getValues(
      `${fieldItemPrefix}.originStageId`
    );
    const selectedStageIds = generateSelectedIds();
    const currentStage: DairyV2Stage = formContext.getValues(
      `stages.${stageIndex}`
    );
    const currentBaselineValues = formContext
      .getValues('stages')
      .map((stage) => ({
        databaseFoundation: calculateInitialDatabaseValue(),
        stageId: stage.id,
        stageType: stage.type,
        stageName: stage.name,
        farmId,
        productionProcessName,
      }));
    return [...farms, ...currentBaselineValues]
      .filter(
        (farm) =>
          farm.databaseFoundation === calculateInitialDatabaseValue() ||
          !farm.databaseFoundation
      )
      .filter(
        (farm) =>
          !selectedStageIds.includes(farm.stageId) ||
          currentlySelectedStage === farm.stageId
      )
      .filter((farm) => allowedStagesForFarm.includes(String(farm.stageType)))
      .filter((farm) => farm.farmId === currentlySelectedFarm)
      .filter((farm) => farm.stageId !== currentStage?.id)
      .map((farm) => ({
        text: `${farm.productionProcessName} - ${farm.stageName} (${String(farm.stageType)})`,
        value: farm.stageId,
      }));
  };

  const stageSelection = async (e: ReactChangedType) => {
    const selectedStageId: string = e.target.value as string;
    const farm: DairyV2Farms | undefined = farms.find(
      (f: DairyV2Farms) => f.stageId === selectedStageId
    );
    if (farm) {
      // Stage selected from other farm
      formContext.setValue(`${fieldItemPrefix}.farmName`, farm.farmName);
      formContext.setValue(`${fieldItemPrefix}.stageName`, farm.stageName);
      formContext.setValue(`${fieldItemPrefix}.stageType`, farm.stageType);
      formContext.setValue(`${fieldItemPrefix}.baselineRef`, farm.reference);
    } else {
      // Stage selected from this farm
      const currentStage: DairyV2Stage = formContext.getValues(
        `stages.${stageIndex}`
      );
      const foundStage = formContext
        .getValues('stages')
        .filter((stage) => allowedStagesForFarm.includes(String(stage.type)))
        .filter((stage) => currentStage?.id !== stage.id)
        .find((stage) => selectedStageId === stage.id);
      if (foundStage) {
        formContext.setValue(`${fieldItemPrefix}.farmName`, farmName);
        formContext.setValue(`${fieldItemPrefix}.stageName`, foundStage.name);
        formContext.setValue(`${fieldItemPrefix}.stageType`, foundStage.type);
        formContext.setValue(`${fieldItemPrefix}.baselineRef`, '');
      }
    }
    await formContext.trigger(`${fieldItemPrefix}.originStageId`);
  };

  const defaultFarm = (): string => {
    const options = farmOptions();
    const farm: string =
      formContext.getValues(`${fieldItemPrefix}.farmId`) || farmId;
    const farmExists = options?.filter(
      (option: { value: string }) => option.value === farm
    )?.length;
    return farmExists ? farm : '';
  };

  const defaultStage = (): string => {
    const options = stageOptions();
    const stageId: string = formContext.getValues(
      `${fieldItemPrefix}.originStageId`
    );
    const stageExists = options?.filter(
      (option) => option.value === stageId
    )?.length;
    return stageExists ? stageId : '';
  };

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.origin`}
        value={InputAnimalOrigin.ProductionSystem}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.id`}
        value={formContext.getValues(`${fieldItemPrefix}.id`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageType`}
        value={formContext.getValues(`${fieldItemPrefix}.stageType`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.farmName`}
        value={formContext.getValues(`${fieldItemPrefix}.farmName`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.stageName`}
        value={formContext.getValues(`${fieldItemPrefix}.stageName`)}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${fieldItemPrefix}.baselineRef`}
        value={formContext.getValues(`${fieldItemPrefix}.baselineRef`)}
      />
      <DsmGrid className={classes.additionalEntriesBlockHolder}>
        {formType !== FormType.View && (
          <DsmButton
            variant="text"
            style={{ position: 'relative', width: '100%' }}
            onClick={() => {
              removeHandler(itemIndex);
            }}
          >
            <DsmIcon
              name="general/x-close"
              style={{
                position: 'absolute',
                color: 'var(--dsm-color-neutral-darker',
                right: '0',
              }}
            />
          </DsmButton>
        )}
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.farmId`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.FARM',
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.FARM.ADORNMENT',
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.FARM.TOOLTIP',
            })}
            options={farmOptions()}
            changeHandler={invalidateStageSelection}
            required
            defaultValue={defaultFarm()}
          />
        </div>
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <ReactHookDsmSelect
            style={{ width: '100%' }}
            name={`${fieldItemPrefix}.originStageId`}
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.DAIRY.INPUT.STAGE`,
            })}
            disabled={formType === FormType.View}
            adornment={intl.formatMessage({
              id: `SUSTELL.STAGE.DAIRY.INPUT.STAGE.ADORNMENT`,
            })}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.STAGE.TOOLTIP',
            })}
            options={stageOptions()}
            changeHandler={async (e: ReactChangedType) => {
              await stageSelection(e);
              if (triggerSelectionError)
                await formContext.trigger(
                  `stages.${stageIndex}.stageData.input.selection`
                );
            }}
            required
            defaultValue={defaultStage()}
          />
        </div>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.numberAnimals`}
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE`,
          })}
          adornment={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.ADORNMENT`,
          })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_ENTERING_STAGE.TOOLTIP`,
          })}
          required
          type="number"
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.numberAnimals`
          )}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.distanceTruck`}
          type="number"
          label={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK`,
          })}
          adornment={distanceUnit}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.DISTANCE_TRUCK.TOOLTIP`,
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.distanceTruck`
          )}
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.weightLoss`}
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS',
          })}
          disabled={formType === FormType.View}
          adornment="%"
          type="number"
          tooltip={intl.formatMessage({
            id: `SUSTELL.STAGE.DAIRY.INPUT.WEIGHT_LOSS.TOOLTIP`,
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.weightLoss`
          )}
        />
      </DsmGrid>
    </>
  );
};

export default DairyV2InputPartInternalSource;
